<div class="membership">

  <div class="body">
    <div class="title-container">
      <p class="title blue">
        Solo para clientes
      </p>
      <p class="title">
        Obtén certificaciones ilimitadas
      </p>
      <p class="description">Solo para ti, que ya te has certificado, te ofrecemos acceso exclusivo a nuestra membresía.
      </p>
    </div>
  
    <div class="items">
  
      <p class="price">
        16,900 COP / mes
      </p>
  
      <ul class="item-list">
        <li>
          <span class="icon-tick t-membership"></span>
          Certifícate ilimitadamente en +6000 cursos.
        </li>
        <li>
          <span class="icon-tick t-membership"></span>
          Revision de actividades y retroalimentación.
        </li>
        <li>
          <span class="icon-tick t-membership"></span>
          Soporte prioritario.
        </li>
        <li>
          <span class="icon-tick t-membership"></span>
          <b>Sin compromisos, cancela cuando quieras.</b>
        </li>
      </ul>
  
      <button class="cta btn-membership">{{notice && notice?.cta ? 'Comprar Membresía' : 'Comprar Membresía'}}
      </button>
  
  
    </div>
  </div>

  <tool-bar [timer]="counter" [timeToSkip]="notice.Event.skip_options.wait_time" [typeNotice]="notice.type" (playPause)="playPause.emit()" (skip)="closed.emit(true)"></tool-bar>
</div>