<div class="container-default" *ngIf="notice?.type == 'IMAGE'">

  <div class="progress-bar"
    [ngStyle]="{'background': counter == 0 ? '#F2F6FF': 'radial-gradient(closest-side, white 79%, transparent 80% 100%), conic-gradient(#2A79FF calc('+counter+'*'+ (100/waitTime)+'%), #F2F6FF 0)', 'cursor': counter == 0 ? 'pointer': null}"
    (click)="counter == 0 ? closed.emit() : null ">
    <span *ngIf="counter > 0">{{counter}}</span>
    <span *ngIf="counter == 0" class="icon-cross progress"></span>
  </div>
  <div class="body">
    <img [src]="notice?.media?.url || '../../../..//assets/img/img-default.png'" alt="">
  
    <div class="cta" (click)="clickUrl.emit()">{{notice && notice?.cta ? (notice.cta | ctaType) : 'Mas información'}}</div>
  </div>
</div>