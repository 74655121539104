import { Component, ElementRef, HostListener, Inject, Input, ViewChild } from '@angular/core';
import { AppService } from './app.service';
import { ActivatedRoute } from '@angular/router';
import { AdModel, ClassroomModel, CourseAdModel, DataInput, Profile, allowedOrigins } from './models/app.models';
import { AuthService } from './auth/auth.service';
import { Observable, Observer, Subject, Subscription, debounceTime, forkJoin, interval, lastValueFrom, map, of } from 'rxjs';
import { SpeedTestService } from 'ng-speed-test';
import { VideoNoticeComponent } from './components/video-notice/video-notice.component';
import { InformativeNoticeComponent } from './components/informative-notice/informative-notice.component';
import { PlatformLocation, isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public data: DataInput;
  public notice: AdModel;
  public notices: AdModel[] = [];
  public location: string;
  bandWith: number = 0;
  counter: number = 15;
  sources = 0;
  secondInteraction = false;
  urlVideo: string;
  showControls: boolean = false;
  showAd = false;
  loadingVideo = true
  waitTime = 0
  ads_found: any = []
  payments = 0;
  isPC = true;
  startAdTime: Date;
  finishAdTime: Date;
  timeSpentUpdated = false;

  @ViewChild(VideoNoticeComponent) videoNotice: VideoNoticeComponent;
  @ViewChild(InformativeNoticeComponent) informativeNotice: InformativeNoticeComponent;
  // @ViewChild('video') video!: ElementRef;
  @ViewChild('card') card: ElementRef;
  eventSource: MessageEvent;

  constructor(
    private _appService: AppService,
    private _activedRoute: ActivatedRoute,
    private speedTestService: SpeedTestService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private platformLocation: PlatformLocation
  ) {

  }

  ngOnInit() {
    console.log('%c av - 2.0.2', 'color: white; background:blue');
    this.speedTestService.getKbps(
      { iterations: 5 }
    ).subscribe(
      (speed) => {
        // console.log('%c Your speed is: ' + speed, 'color: white; background: #1d448e');
        this.bandWith = speed;
      }
    );

    if (isPlatformBrowser(this.platformId)) {
      this.isPC = true;
    } else {
      this.isPC = false;
    }

    this._appService.iframeEmitter.subscribe(r => {
      //Este emitter reaccionará cada que haya que enviar events a la app padre
      const response = r;
      (this.eventSource?.source as Window).postMessage(response, this.eventSource.origin);
    });
  }

  @HostListener('window:message', ['$event'])
  iframeEventListener(event: MessageEvent) {

    //Este listener reacciona cada que la app padre envie events hasta esta app
    if (allowedOrigins(event.origin)) {
      this.eventSource = event;
      this.iframeTemplateGenerator(event);
    }
  }

  iframeTemplateGenerator(event?: MessageEvent) {

    // Decode the data that comes on queryStringParams
    const dataQueryStringParams = this._activedRoute.snapshot.queryParamMap.get("data");
    const dataDecode = dataQueryStringParams ? dataQueryStringParams : event?.data;



    if (dataDecode) {
      try {
        // Intentar decodificar y parsear los datos
        let parsedData: any;
        if (typeof dataDecode == 'string') {
          parsedData = JSON.parse(atob(dataDecode));
          // parsedData = JSON.parse(decodeURIComponent(dataDecode));
        } else {
          parsedData = dataDecode;
        }
        this.saveData(parsedData);
        this.location = this.data.location;

        // Hacer la validación del token del usuario que está logueado
        if (!this._appService.token) {
          this._appService.parseUserToken(this.data);
        }

        this._appService.getCountPayments(this._appService.user_data.id).subscribe((res: any) => {
          // Cantidad de certificados que tiene activos el usuario
          if (res.count) {
            this.payments = res.count;
          }
        });

        // Si hay un token previamente cargado
        if (this._appService.token) {
          if (this.data.is_video) {
            // Si se encuentra viendo un video
            // Gestionar anuncios para el classroom
            if (this.location == 'classroom') {
              if (this.data.showing || this.data.pause != undefined) {
                this.showingAd();
              } else {
                this.classroomAdsManager(this.data.classroomView, this.data.certificate_status, this.data.courseProgress, this.data.mobile);
              }
            }
            // Gestionar anuncios para el perfil
            else if (this.location == 'profile') {
              this.profileAdsManager(this.data.ProfileView);
            }
          } else {
            this._appService.iframeEmitter.next({ show: false, classroomView: undefined })
            this.showAd = false;
          }
        } else {
          console.log("|Warning|: UserTokenNotProvided");
        }
      } catch (error) {
        console.error("Error decoding or parsing data:", error);
        // Aquí puedes manejar el error, mostrar un mensaje al usuario, etc.
      }
    }


    // if (dataDecode) {
    //   // this.saveData(JSON.parse(atob(dataDecode)))
    //   this.saveData(JSON.parse(decodeURIComponent(dataDecode)))
    //   this.location = this.data.location;

    //   // Hacer la validación del token del usuario que está logueado
    //   if (!this._appService.token) {
    //     this._appService.parseUserToken(this.data)
    //   }

    //   this._appService.getCountPayments(this._appService.user_data.id).subscribe((res: any) => {
    //     //Cantidad de certificadors que tiene activos el usuario
    //     if (res.count) {
    //       this.payments = res.count;
    //     }
    //   })

    //   // Si hay un token previamente cargado
    //   if (this._appService.token) {
    //     if (this.data.is_video) {
    //       //Si se encuentra viendo un video
    //       // Gestionar anuncios para el classroom
    //       if (this.location == 'classroom') {
    //         if (this.data.showing || this.data.pause != undefined) {
    //           this.showingAd()
    //         } else {
    //           this.classroomAdsManager(this.data.classroomView, this.data.certificate_status, this.data.courseProgress, this.data.mobile);
    //         }
    //       }

    //       // Gestionar anuncios para el perfil
    //       else if (this.location == 'profile') {
    //         this.profileAdsManager(this.data.ProfileView);
    //       }

    //     } else {
    //       this._appService.iframeEmitter.next({ show: false })
    //       this.showAd = false;
    //     }
    //   }

    //   // No hay token de usuario
    //   else {

    //     console.log("|Warning|: UserTokenNotProvided")

    //   }
    // }



  }

  saveData(data: any) {

    if (this.data) {
      if (data.location !== undefined) {
        this.data.location = data.location;
      }
      if (data.showing !== undefined) {
        this.data.showing = data.showing;
      }
      if (data.pause !== undefined) {
        this.data.pause = data.pause;
      }
      if (data.classroomView !== undefined) {
        this.data.classroomView = data.classroomView;
      }
      if (data.certificate_status !== undefined) {
        this.data.certificate_status = data.certificate_status;
      }
      if (data.courseProgress !== undefined) {
        this.data.courseProgress = data.courseProgress;
      }
      if (data.mobile !== undefined) {
        this.data.mobile = data.mobile;
      }
      if (data.certificate_id !== undefined) {
        this.data.certificate_id = data.certificate_id;
      }
      if (data.scholarship !== undefined) {
        this.data.scholarship = data.scholarship;
      }
      if (data.is_video !== undefined) {
        this.data.is_video = data.is_video;
      }
    } else {
      this.data = data;
    }
  }

  async classroomAdsManager(data: ClassroomModel, certificate: number, progress: number, mobile?: boolean) {

    // Esta variable es para saber cuantos parametros vienen incluidos en la data que envió el classroom
    const params = Object.keys(data)

    // Para buscar todos los anuncios pertenecientes a un curso se necesita el course_id
    if (params.includes('course_id')) {

      const { course_id, source_id, category_id } = data

      await lastValueFrom(this.getAdsCourses(course_id)) // Obtener los anuncios de un curso

      const coursesAds = this._appService.coursesAds;
      
      console.log("🚀 ~ AppComponent ~ classroomAdsManager ~ coursesAds:", coursesAds)

      

      // Si se requiere buscar más que solo los anuncios de un curso (Buscar un anuncio en especifico)
      if (params.length > 1) {
        this.ads_found = []
        this.showAd = false;
        coursesAds.forEach((ad: AdModel) => {
          // Compruebo que el source_id que me envia el classroom esté incluido en los sources_id del course_ad
          if ((ad?.ad_course?.source_id && ad?.ad_course?.source_id.includes(source_id?.toString())) || ad['config'] == 'ALL_COURSES' || (ad['config'] == 'IN_CATEGORY' && ad.category_id == category_id ) ) {
            this.ads_found.push(ad);
          }
        });
        
        console.log("🚀 ~ AppComponent ~ coursesAds.forEach ~ this.ads_found:", this.ads_found)
        

        this.sources += 1;
        this.cleanAds()
        this.inauguralShown = false;

      }

    }
  }

  // Eliminar anucnios que no cumplen con las condiciones recibidas del classroom
  cleanAds() {

    this.showAd = false;
    this.ads_found = this.ads_found.filter((ad: any) => {
      let actions = ad.Event.actions.map((item: any) => item.name)
      console.log("--------\n", ad.config)
      console.log(ad.name)
      let response = this.validateActions(actions, this.data.certificate_status, this.data.courseProgress)
      console.log("result: ",  response)
      return response
    })

    if (this.notice && this.notice.interactions) {
      const CTAs = ['get_certificate', 'buy', 'enroll', 'more_info', 'download', 'contact', 'get_discount', 'wa_contact', 'mail_contact', 'dm_contact', 'paused', 'cta_in_video'];
      CTAs.forEach(cta => {
        (this.notice.interactions[cta]) = false;
      });
    }

    console.log("🚀 ~ AppComponent ~ coursesAds.forEach ~ ads_found:", this.ads_found)

    // If source change while displaying an ad, update the time spent
    if (this.notice && !this.timeSpentUpdated) {
      this.finishAdTime = new Date()
      this._appService.putUserAdTracking(this.notice.tracking.id, { time_spent: this.getTimeDifference(this.startAdTime, this.finishAdTime) }).subscribe()
    }
    this.timeSpentUpdated = false;

    if (this.interval_subscription) {
      // In case of switching to another source while displaying an ad, cancel the interval
      this.interval_subscription.unsubscribe();
    }

    setTimeout(() => {
      this.getAdById(this.ads_found, true, true);
    }, 10);
  }

  getAdById(ads: any, start: boolean, doRequest?: boolean) {
    this.whichAdToShow(ads, start, doRequest).subscribe((ad: string) => {
      let adToShow = ad; //"79069e51-e22d-4171-b845-fe9f9294b8b3";  //

      // Si se encontró un anuncio para este source
      if (adToShow) {
        let index = this.ads_found.findIndex((ad: any) => ad.id == adToShow)
        let tracking = this.ads_found[index]?.tracking;
        this.ads_found[index].viewed = true;

        this.notice = this.ads_found[index] // this.anuncioTemporal;

        if (this.notice.type == 'POLL') {
          this._appService.getPollById(this.notice.content_id).subscribe(res => {
            if (res.valid && res.data.length > 0) {

              this._appService.getQuestions(this.notice.content_id).subscribe(resQ => {
                if (resQ.valid) {
                  let poll = res.data[0]
                  poll.questions = resQ.data.sort((a: any, b: any) => a.order - b.order);
                  this.notice.poll = poll;

                  this.showOrNotAd(this.notice, start, tracking)
                }
              })
            } else {
              this.showOrNotAd(this.notice, start, tracking, true)
            }
          })
        } else if (this.notice.type == 'VACANCY') {
          this._appService.getVacancy(this.notice.content_id).subscribe((res: any) => {
            if (res.valid) {
              this.notice.vacancy = res.data[0];

              this._appService.getBusiness(this.notice.vacancy.business_id).subscribe((res: any) => {
                if (res.valid) {
                  this.notice.vacancy.business = res.data[0];

                  this.showOrNotAd(this.notice, start, tracking)
                }
              });
            } else {
              this.showOrNotAd(this.notice, start, tracking, true)
            }
          });
        } else {
          this.showOrNotAd(this.notice, start, tracking)
        }

      } else {
        this._appService.iframeEmitter.next({ hasAd: false, show: false, classroomView: this.data?.classroomView })
      }
    });
  }

  showOrNotAd(notice: AdModel, start: boolean, tracking?: any, invalidAd?: boolean) {
    let deleteAd = false;
    if ((tracking && this.notice.Event.once) || (notice.type == 'POLL' && !this.notice.Event.once && tracking && tracking?.success == true)) {
      deleteAd = true;
    }

    // Le indicamos al padre si se pudo obtener el anuncio o no
    if (!invalidAd) {
      this.showAd = true;
      this._appService.iframeEmitter.next({
        hasAd: true,
        show: true,
        type: this.notice.type,
        classroomView: this.data?.classroomView
      })
      this.startAdTime = new Date()
      this.userAdTracking(tracking, this.notice.id)

      this.startAd();

      this.interactions = {}
      // if (mobile || this.sources > 0) {
      //   this.showingAd(true)
      //   // console.log('PLAY FOR MOBILE')
      // }
    } else if (deleteAd || invalidAd) {
      this.ads_found = this.ads_found.filter((ad: any) => ad.id != this.notice.id)

      if (this.ads_found.length) {
        this.getAdById(this.ads_found, start, false)
      } else {
      }
    } else {
      let ads = this.ads_found.filter((ad: any) => ad.id != this.notice.id)
      if (ads.length) {
        this.getAdById(ads, start, false)
      } else {
      }
    }


  }

  whichAdToShow(ads: any, startVideo: boolean, doRequest?: boolean): Observable<string> {
    let not_viewed_ads: any = doRequest ? [] : ads;
    let observables: Observable<any>[] = [];

    if (doRequest) {
      let filterAds = ads.filter((ad: any) => !ad.hasOwnProperty('tracking'))

      observables = filterAds.map((ad: any) => {
        if (ad.inaugural) {
          return this._appService.getUserTracking(ad.id, this._appService.user_data.id, this.data.classroomView.course_id.toString());
        } else {
          return this._appService.getUserTracking(ad.id, this._appService.user_data.id);
        }
      });
    } else {
      // Si no es necesario hacer solicitudes, no se crean observables
      return of(this.calculateAdToShow(ads, startVideo));
    }

    if (observables.length === 0) {
      // No hay observables, devolver el resultado directamente
      return of(this.calculateAdToShow(ads, startVideo));
    }

    return forkJoin(observables).pipe(
      map((responses: any[]) => {
        responses.forEach((r: any, index: number) => {
          if (!r.valid) {
            ads[index].tracking = null;
            not_viewed_ads.push(ads[index]);
          } else {
            ads[index].tracking = r.data;
          }
        });

        return this.calculateAdToShow(not_viewed_ads, startVideo);
      })

    );
  }

  searchChild(ads: any[], parent_id: string, adsSort: any[]): void {
    let index = ads.findIndex((ad: any) => ad.parent == parent_id && ad.parent != ad.id)
    if (index != -1) {
      adsSort.push(ads[index])
      this.searchChild(ads, ads[index].id, adsSort)
    }
  }

  // Para saber si en este source se ha mostrado o no un video inaugural
  inauguralShown = false;
  private calculateAdToShow(ads: any[], startVideo: boolean): string {
    if (this.sources == 1 && !this.firstPause) {
      return ''
    }
    ads = ads.filter(ad => startVideo ? ad.Event.actions.map((item: any) => item.name).includes('START_VIDEO') : ad.Event.actions.map((item: any) => item.name).includes('PAUSED_VIDEO'))

    let specific_ads = ads.filter((ad: any) => ad.config == 'SPECIFIC' && !ad?.tracking);
    let category_ads = ads.filter((ad: any) => ad.config == 'IN_CATEGORY' && !ad?.tracking);
    let all_courses = ads.filter((ad: any) => (ad.config == 'ALL_COURSES' && !ad?.tracking));

    let inauguralAds = this.ads_found.filter((ad: any) => ad.inaugural && (startVideo ? ad.Event.actions.map((item: any) => item.name).includes('START_VIDEO') : ad.Event.actions.map((item: any) => item.name).includes('PAUSED_VIDEO')));

    // Ordenar los anuncios inaugurales
    let adsSort: any[] = [];

    let indexFirst = inauguralAds.findIndex((ad: any) => ad.parent == ad.id)
    if (indexFirst != -1) {
      adsSort.push(inauguralAds[indexFirst])
      this.searchChild(inauguralAds, inauguralAds[indexFirst].id, adsSort)
    }

    adsSort = adsSort.filter((ad: any) => !ad?.tracking)

    // Si hay anuncios inaugurales, no se ha mostrado ninguno en este source y los anuncios están completos
    if (adsSort.length > 0 && !this.inauguralShown) {

      this.inauguralShown = true;
      return adsSort[0].id;
    } else if (specific_ads.length > 0) {
      return this.randomAd(specific_ads)

    } else if (category_ads.length > 0) {
      return this.randomAd(category_ads)

    } else if (all_courses.length > 0) {
      return this.randomAd(all_courses)

    } else {
      // Si no hay anuncios que no se hayan visto, se escoge uno de todos los anuncios
      this.ads_found = this.ads_found.filter((ad: any) => !ad.inaugural)

      let allAds = this.ads_found.filter((ad: any) => startVideo ? ad.Event.actions.map((item: any) => item.name).includes('START_VIDEO') : ad.Event.actions.map((item: any) => item.name).includes('PAUSED_VIDEO'))

      return this.randomAd(allAds)
    }

  }

  randomAd(ads: any[]) {
    let random = Math.floor(Math.random() * (ads.length));
    if (random <= ads.length - 1) {
      return ads[random].id;
    }
  }

  validateActions(actions: any, certificate_status: number, progress: number) {
    return actions.every((condition: any) => {

      console.log("condition: ", condition)

      switch (condition) {
        case 'ACTIVATE_CERTIFICATE':
          return certificate_status == 1;

        case 'ACTIVE_CERTIFICATE':
          return certificate_status == 1;

        case 'INACTIVE_CERTIFICATE':
          return certificate_status != 1;

        case 'COURSE_PROGRESS_50+':
          return progress >= 50;

        case 'COURSE_PROGRESS_50-':
          return progress <= 50;

        case 'ONLY_CLIENTS':
          return this.payments > 0;

        case 'IDENTITY_VERIFICATION':
          return true;

        default:
          return true;
      }
    });
  }

  profileAdsManager(data: Profile) { }


  /**
   * Esta función realiza la busqueda en la BD cuando la variable _appService.coursesAds está vacia
   * de lo contrario simplemente indica que se puede acceder a los valores previamente guardados
   * @param course_id
   * @returns
   */

  getAdsCourses(course_id: string | number | undefined, category_id?: string | number): Observable<boolean> {

    return new Observable((observer: Observer<boolean>) => {
      if (this._appService.coursesAds.length == 0) {
        const adsObservable = this._appService.getAds({config: 'ALL_COURSES', status: 'ACTIVE', details: true});
        const categoryAdsObservable = this._appService.getAds({category_id: 76, status: 'ACTIVE', details: true});
        const coursesAdsObservable = this._appService.getAdsCourses(course_id);

        forkJoin([adsObservable, coursesAdsObservable, categoryAdsObservable]).subscribe({
          next: ([adsResponse, coursesAdsResponse, categoryAdsResponse]) => {
            const { valid: adsValid, data: adsData } = adsResponse;
            const { valid: categoryAdsValid, data: categoryAdsData } = categoryAdsResponse;
            const { valid: coursesAdsValid, data: coursesAdsData } = coursesAdsResponse;

            if (adsValid || coursesAdsValid || categoryAdsValid) {
              let adsActive = adsData.filter((ad: any) => ad.status == 'ACTIVE');
              let categoryAdsActive = categoryAdsData.filter((ad: any) => ad.status == 'ACTIVE');
              let coursesAdsActive = coursesAdsData.filter((ad: any) => ad.status == 'ACTIVE');

              adsActive.forEach((element: any) => this._appService.coursesAds.push(element));
              categoryAdsActive.forEach((element: any) => this._appService.coursesAds.push(element));
              coursesAdsActive.forEach((element: any) => this._appService.coursesAds.push(this.rearrangeObject(element)));

              observer.next(true);
              observer.complete();
            } else {
              observer.next(false);
              observer.complete();
            }
          },
          error: (error: any) => {
            observer.error(error);
          }
        });
      } else {
        observer.next(true);
        observer.complete();
      }
    });
  }

  rearrangeObject(originalObj: any): any {
    const newObj: any = originalObj.Ad
    newObj.ad_course = {}
    delete originalObj.Ad

    // Mover propiedades dinámicamente a ad_course
    for (const key in originalObj) {
      if (originalObj.hasOwnProperty(key) && key !== 'ad') {
        newObj.ad_course[key] = originalObj[key];
      }
    }

    return newObj;
  }

  firstPause = false;
  showingAd(showing?: boolean) {
    // Anuncio se mostro en el classroom
    if (this.data.showing || showing) {
      this.startAd()
      this.data.showing = false;

    } else {
      // Evento Pause en el classroom
      this.firstPause = true //para saltar la validadcion del primer source
      let not_viewed_ads = this.ads_found.filter((ad: any) => !ad.tracking)
      this.data.pause = undefined;

      this.getAdById(not_viewed_ads, false, false)

    }
  }

  startAd() {
    if (this.notice.type == 'VIDEO') {
      this.notice.media.urlVideo = this.notice.media.url_h
      if (this.bandWith < 1200) {
        let url = this.notice.media.url_h.split('/')
        let replace = url[url.length - 1]
        this.notice.media.url_h = this.notice.media.url_h.replace(replace, 'video_426x240_lq.mkv')
      }
      // setTimeout(() => {
      //   // this.videoNotice.playVideo()         
      // }, 100);

    } else {
      this.timerIsPaused = false;
      this.counterAd();
    }
  }

  playPause() {
    this.timerIsPaused = !this.timerIsPaused;

    if (this.notice.interactions === undefined) {
      this.notice.interactions = {};
    }

    if (!this.notice.interactions.paused) {
      this.notice.interactions.paused = true;
      this.updateInteractionsTracking('paused')
    }
  }

  timerIsPaused = false;
  interval_subscription: any;
  counterAd() {
    if (this.interval_subscription) {
      this.interval_subscription.unsubscribe(); // Cancela el intervalo existente si hay uno en progreso
    }

    this.counter = 0;

    this.interval_subscription = interval(20).subscribe(() => {
      if (!this.timerIsPaused) {
        if (this.counter < 15) {
          this.counter = this.counter + 0.02;
        } else {
          this.interval_subscription.unsubscribe();
          this.closeNotice();
        }
      }
    });
  }

  closeNotice(skiped?: any) {

    if (skiped) {
      this.updateInteractionsTracking('omitted')
    }

    if (this.notice.type == 'VIDEO') {
      this.videoNotice.video.nativeElement.pause()
      this.videoNotice.video.nativeElement.currentTime = 0
      this.videoNotice.video.nativeElement.mute = false
    }
    this._appService.iframeEmitter.next({ hasAd: true, show: false, adInteraction: "CLOSED", classroomView: this.data?.classroomView })
    this.showAd = false;
    if (this.interval_subscription) {
      this.interval_subscription.unsubscribe();
    }

    this.finishAdTime = new Date()
    this._appService.putUserAdTracking(this.notice.tracking.id, { time_spent: this.getTimeDifference(this.startAdTime, this.finishAdTime) }).subscribe()
    this.timeSpentUpdated = true;
  }

  /**
   * Verifica el estado del usuario logueado respecto a este anuncio (Si lo ha visto antes o no)
   * Dependiendo de eso realiza ciertas acciones
   */
  userAdTracking(tracking: any, ad_id: string, data?: any) {

    const user_id = this._appService.user_data.id

    let dataPost = {
      origin: 'CLASSROOM',
      course_id: this.data.classroomView.course_id?.toString(),
      owner_id: this.notice.owner_id,
      campaign_id: this.notice.campaign_id,
      view_apps: this.data.mobile ? 'MOBILE' : 'WEBSITE',
      time_spent: parseInt(this.notice.Event.skip_options.wait_time.toString()),
      interaction: this.notice.type == 'POLL' ? 'poll' : undefined,
      view_devices: this.isPC ? 'PC' : 'MOBILE'
    }

    this._appService.postUserAdTracking(ad_id, user_id, dataPost).subscribe(t => {
      if (t.valid) {
        let index = this.ads_found.findIndex((ad: any) => ad.id == ad_id)
        this.ads_found[index].tracking = t.data;
      }
    })
  }

  pollFinished() {
    this._appService.putUserAdTracking(this.notice.tracking.id, { success: true }).subscribe(res => {
      if (res.valid && res.row_count) {
        let index = this.ads_found.findIndex((ad: any) => ad.id == this.notice.id)
        this.ads_found[index].tracking.success = true;
      }
    });

    this.closeNotice()
  }

  goToUrl(key: string) {
    if (this.notice.cta == 'Certificarme' || this.notice.cta == 'Estudiar con certificado') this.notice.cta = 'get_certificate';
    key = key ? key : this.notice.cta;
    let url = '';

    switch (key) {
      case 'buy':
      case 'enroll':
      case 'download':
      case 'cta_in_video':
        if (!this.notice.target_website.url.startsWith('https://') && !this.notice.target_website.url.startsWith('http://')) {
          // Si no tiene prefijo, agregar "https://"
          url = 'https://' + this.notice.target_website.url;
        } else {
          url = this.notice.target_website.url;
        }
        break;
      case 'get_discount':
      case 'get_certificate':
        url = `https://edutin.com/payments/certificate/${this.data.certificate_id}`;
        break
      case 'more_info':
        url = `https://edutin.com/pages/certificate/${this.data.certificate_id}`;
        break

      case 'wa_contact':
        url = `https://wa.me/+13022615750?text=*Hola!* Me gustaría recibir asesoría, mi id de usuario es: ${this._appService.user_data.id}`;
        break
      case 'mail_contact':
        url = `https://edutin.com/helps/requests`;
        break
      case 'dm_contact':
        url = `http://m.me/EdutinAcademy`;
        break

      default:
        url = `https://edutin.com/payments/certificate/${this.data.certificate_id}`;
        break;
    }

    if (this.data.mobile) {
      this._appService.iframeEmitter.next({ show: false, adInteraction: "CTA_CLICK", url: this.notice.target_website.url, classroomView: this.data?.classroomView })
    } else {
      window.open(url, '_blank');
    }

    if (this.notice.interactions === undefined) {
      this.notice.interactions = {};
    }
    if (!this.notice.interactions[key]) {
      this.notice.interactions[key] = true; // Se debe quitar esto para poder hacer el trackingg

      this.updateInteractionsTracking(key);
    }
  }

  interactions: any = {}
  updateInteractionsTracking(cta: string) {
    if (this.interactions[cta]) return;
    if (Object.keys(this.interactions).length == 0) {
      this._appService.putUserAdTracking(this.notice.tracking.id, { interaction: cta }).subscribe()
    } else {

      let dataPost = {
        origin: 'CLASSROOM',
        course_id: this.data.classroomView.course_id?.toString(),
        owner_id: this.notice.owner_id,
        campaign_id: this.notice.campaign_id,
        view_apps: this.data.mobile ? 'MOBILE' : 'WEBSITE',
        time_spent: parseInt(this.notice.Event.skip_options.wait_time.toString()),
        interaction: cta,
        view_devices: this.isPC ? 'PC' : 'MOBILE'
      }

      this._appService.postUserAdTracking(this.notice.id, this._appService.user_data.id, dataPost).subscribe()
    }
    this.interactions[cta] = true;

  }

  getTimeDifference(start: Date, fisnish: Date): number {
    // Calcula la diferencia en milisegundos
    const differenceMs = fisnish.getTime() - start.getTime();

    // Convierte la diferencia a segundos
    const seconds = Math.floor(differenceMs / 1000);

    return seconds;
  }


  // public anuncioTemporal: AdModel = {
  //   id: "79069e51-e22d-4171-b845-fe9f9294b8b3",
  //   principal_text: "Texto Principal",
  //   title: "Programas de Especializados",
  //   cta: "",
  //   style: undefined,
  //   media: {
  //     name: "video_1920x1080_hq.mp4",
  //     type: "video",
  //     url_h: "https://d3puay5pkxu9s4.cloudfront.net/edutinAds/79069e51-e22d-4171-b845-fe9f9294b8b3/video/horizontal/video_1920x1080_hq.mp4",
  //     urlVideo: "https://d3puay5pkxu9s4.cloudfront.net/edutinAds/79069e51-e22d-4171-b845-fe9f9294b8b3/video/horizontal/video_1920x1080_hq.mp4"
  //   },
  //   name: "2. ¿Que es Edutin?",
  //   result: "0",
  //   scope: "0",
  //   prints: "0",
  //   status: "ACTIVE",
  //   type: "VIDEO",
  //   description: "",
  //   target_website: {
  //     label: "Ver programas",
  //     url: "edutin.com/payments/certificate/{certification_id}" //"https://app.edutin.com/academy/{academy_id}"
  //   },
  //   target_form: false,  // No se menciona un formulario objetivo, asumiendo false.
  //   public_id: "22d0b3dc-79cb-4f94-a978-0fe2f1c86e25",
  //   group_id: "f44fdd4e-f7d5-4d7f-9699-d8d838540a7f",
  //   campaign_id: "16ccb18b-c2aa-4915-86fd-df5fdfdd462a",
  //   creator_id: "356745",
  //   event_id: "b2172b69-2864-45c5-be52-fc30bd7cc274",
  //   Event: {
  //     once: false,
  //     skip_options: {
  //       skip_timer: true,
  //       wait_time: "5"
  //     },
  //     creator_id: "356745",
  //     created: "2023-11-14 16:03:25",
  //     id: "b2172b69-2864-45c5-be52-fc30bd7cc274",
  //     name: "Todos",
  //     type: "CUSTOM",
  //     actions: [
  //       {
  //         event_id: "b2172b69-2864-45c5-be52-fc30bd7cc274",
  //         id: "535740b5-bf32-44ee-a795-231c313b19cf",
  //         name: "START_VIDEO",
  //         type: "ACTION"
  //       },
  //       {
  //         event_id: "b2172b69-2864-45c5-be52-fc30bd7cc274",
  //         id: "42073544-e2a4-486c-b916-294bc7b39510",
  //         name: "LOGGED_IN",
  //         type: "CONDITION"
  //       }
  //     ]
  //   },
  //   shown: true,  // Asumiendo que "viewed": true se refiere a que se mostró.
  //   closed: 0,  // No se menciona el número de cierres, asumiendo 0.
  //   content_id: "NONE",
  //   config: "ALL_COURSES",
  //   parent: "41395eb9-d6c1-49dd-9654-c50e6258f788",
  //   inaugural: true,
  //   tracking: {
  //     interaction: "closed",
  //     origin: "CLASSROOM",
  //     view_devices: "PC",
  //     view_apps: "WEBSITE",
  //     time_spent: 5,
  //     success: false,
  //     owner_id: "356745",
  //     campaign_id: "16ccb18b-c2aa-4915-86fd-df5fdfdd462a",
  //     user_id: "425034",
  //     ad_id: "79069e51-e22d-4171-b845-fe9f9294b8b3",
  //     course_id: "12595",
  //     id: "c1a1ac7d-1809-47e3-ac81-69eec52c8111",
  //     created: "2024-08-28T21:12:21.242069Z"
  //   },
  //   owner_id: "356745",
  //   interactions: {
  //     paused: true
  //   }
  // };


}
