import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { AdModel } from 'src/app/models/app.models';

@Component({
  selector: 'video-notice',
  templateUrl: './video-notice.component.html',
  styleUrls: ['./video-notice.component.scss']
})
export class VideoNoticeComponent {

  @Input() notice: AdModel;
  @Input() mobile: boolean;
  @Input() counter: number;
  @Input() course: any;
  @Input() data: any;

  @Output() finished = new EventEmitter<any>();
  @ViewChild('video') video!: ElementRef;
  @Output() playPause = new EventEmitter<any>();
  currentProgress = 0;
  waitTime = 0
  showControls = false;
  videoLength: number;
  cta_url_sleep = true;


  constructor(private _appService: AppService) { }

  ngOnInit(): void {
  }

  playVideo() {
    setTimeout(() => {
      const video = this.video.nativeElement;
      video.currentTime = 0;
      video.volume = 1;
      this.mute = false;
      var promise = video.play();
      this.cta_url_sleep = false;
    }, 100);
  }

  videoPaused = false;
  playPauseVideo() {
    const video = this.video.nativeElement;
    if (video.paused) {
      video.play();
      this.videoPaused = false;
    } else {
      video.pause();
      this.videoPaused = true;
    }

    this.playPause.emit();
  }

  onVolumeChange(event: number) {
    const video = this.video.nativeElement;
    video.volume = event;
  }

  updateProgress(): void {
    const video = this.video.nativeElement;
    this.currentProgress = video.currentTime;
  }

  getVideoLength() {
    this.videoLength = this.video.nativeElement.duration;
  }

  mute = false;
  setVolume() {
    this.showControlsMobile()
    const video = this.video.nativeElement;
    if (this.mute) {
      video.volume = 1;
      this.mute = false;
    } else {
      video.volume = 0;
      this.mute = true;
    }
  }

  timer: any;
  showControlsMobile() {
    if (this.notice.type == 'VIDEO' && this.mobile) {
      this.showControls = true;

      if (this.timer) {
        clearTimeout(this.timer);
      }

      this.timer = setTimeout(() => {
        this.showControls = false;
      }, 2000);
    }
  }

  goToUrl(url: string, reference: string) {

    let url_transform = this.replacePlaceholders(url, [this.notice, this.course, this.data]);

    if (url_transform) {
      window.open(url_transform, '_blank');
    }
  }


  replacePlaceholders(url: string, sources: any) {

    const parameterMap: any = {
      "academy_id": ["academy_id", "category_id"],
      "course_id": ["course_id", "curso_id"],
      "notice_id": ["notice_id", "noticia_id"],
      "certification_id": ["certification_id", "certificate_id"]
    };

    // Agregar "https://" si no está presente al inicio de la URL
    if (!url.startsWith("https://")) {
      url = "https://" + url;
    }

    return url.replace(/{(\w+)}/g, function (match, key) {
      for (let commonKey in parameterMap) {
        if (parameterMap[commonKey].includes(key)) {
          // Itera sobre las fuentes (notice, course)
          for (let source of sources) {
            // Itera sobre las claves asociadas al commonKey
            for (let alias of parameterMap[commonKey]) {
              if (source.hasOwnProperty(alias)) {
                return source[alias];
              }
            }
          }
        }
      }
      // Si no encuentra la clave en ninguno de los objetos, retorna el placeholder tal cual
      return match;
    });
  }
}
