import { Component, EventEmitter, Input, Output } from '@angular/core';
import { interval } from 'rxjs';
import { AdModel } from 'src/app/models/app.models';

@Component({
  selector: 'discount',
  templateUrl: './discount.component.html',
  styleUrls: ['./discount.component.scss']
})
export class DiscountComponent {

  @Input() notice: AdModel;
  @Input() scholarship: any;
  @Input() counter: number;
  @Output() closed = new EventEmitter<boolean>();
  @Output() playPause = new EventEmitter<any>();
  @Output() clickCertification = new EventEmitter<boolean>();
  waitTime = 0;
  constructor() { }

  ngOnInit(): void {
    this.waitTime = this.notice.Event.skip_options.wait_time
    
  }

}
