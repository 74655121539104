<div class="video" *ngIf="notice?.type == 'VIDEO'" (mouseenter)="showControls = true" (mouseleave)="showControls = false">

  <div class="spinner" *ngIf="loadingVideo">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>

  <video (canplay)="loadingVideo = false; getVideoLength()" #video autoplay (ended)="finished.emit()"
    (timeupdate)="updateProgress()"
    [src]="mobile && notice?.media?.url_v ?  notice.media.url_v : notice?.media?.url_h ? notice.media.url_h : notice.media.urlVideo "
    (click)="showControlsMobile()">
  </video>

  <tool-bar [timer]="currentProgress" [videoLength]="videoLength" [timeToSkip]="notice.Event.skip_options.wait_time ? notice.Event.skip_options.wait_time : 5" [typeNotice]="notice.type" (volumeChange)="onVolumeChange($event)" (skip)="finished.emit(true)" (playPause)="playPauseVideo()"></tool-bar>

  <!-- CTA Buttom-->
  <div class="cta_card" *ngIf="notice.target_website?.url" [class.show]="cta_url_sleep">
    <span class="logo"> <i class="icon-edutin"></i> </span>
    <span class="info">
      <strong>{{notice.title}}</strong>
      <span>www.edutin.com</span>
    </span>
    <span class="cta" (click)="goToUrl(notice.target_website.url, 'ACADEMIES')">{{notice?.target_website?.text ? notice.target_website.text : "Conoce más"}}</span>
  </div>
</div>