import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { AdModel, Option, Poll, Question } from 'src/app/models/app.models';

@Component({
  selector: 'poll',
  templateUrl: './poll.component.html',
  styleUrls: ['./poll.component.scss']
})
export class PollComponent {

  @Input() poll: Poll;
  @Input() notice: AdModel;
  @Output() finish = new EventEmitter<any>();
  currentQuestion = 0;
  @Input() counter: number;
  waitTime = 0;
  shorText = ''

  constructor(private appService: AppService) { }

  totalQuestions = 0; // Number of questions that are required + the ones that are conditional
  counterQuestions = 1; // Number of questions that have been answered
  ngOnInit(): void {
    this.waitTime = this.notice.Event.skip_options.wait_time

    this.poll.questions.forEach((question) => {
      if (question.required) {
        this.totalQuestions++;
      }

      if (question.conditional) {
        this.totalQuestions++;
      }
    });

  }

  selectOption(option: Option) {
    switch (this.poll.questions[this.currentQuestion].type) {
      case 'UNIQUE_CHOICE':
        this.poll.questions[this.currentQuestion].options.forEach((opt) => {
          if (opt.id == option.id) {
            opt.selected = true;
          } else {
            opt.selected = false;
          }
        });
        break;

      case 'MULTIPLE_CHOICE':
        option.selected = option.selected ? false : true;
        break;

    }
  }

  answers: any = []
  nextQuestion() {
    this.answers.push({
      answer: this.poll.questions[this.currentQuestion].type == 'TEXT_SHORT'? [this.shorText] : this.poll.questions[this.currentQuestion].options.filter((opt) => opt.selected),
      questionId: this.poll.questions[this.currentQuestion].id,
      parent_id: this.poll.questions[this.currentQuestion].parent_id || undefined
    });

    if (this.counterQuestions < this.totalQuestions) {
      if (!this.poll.questions[this.currentQuestion].conditional) {
        if(this.currentQuestion + 1 < this.poll.questions.length) {

          let index = this.poll.questions.findIndex((question) => question.required == true && question.order > this.poll.questions[this.currentQuestion].order);
          if(index >= 0) {
            this.currentQuestion = index;
          }else {
            this.finishPoll();
          }
        }else {
          this.finishPoll();
        }
      } else {
        let optionIndex = this.poll.questions.findIndex((question) => question.id == this.answers[this.answers.length -1].answer[0].redirect);
        if (optionIndex >= 0) {
          this.poll.questions[optionIndex].parent_id = this.poll.questions[this.currentQuestion].id;
          this.currentQuestion = optionIndex;
        }else {
          this.finishPoll();
        }
      }

      this.shorText = ''
      this.counterQuestions++;
    } else {
      this.counterQuestions++;

      this.answers.forEach((ans: any) => {
        ans.answer.forEach((obj: any) => {
          if (obj.selected) delete obj.selected;
        });
        
        let dataQuestions = {
          poll_id: this.poll.id,
          answers: ans.answer,
          parent_id: ans.parent_id
        }
        
        this.appService.postAnswer(ans.questionId, dataQuestions).subscribe();  
      });

      setTimeout(() => {
        this.finishPoll();        
      }, 500);
    }
  }

  isAnswered() {
    return this.poll.questions[this.currentQuestion].options.some((opt) => opt.selected);
  }

  finishPoll() {
    this.shorText = ''
    this.currentQuestion = 0;

    this.finish.emit();
  }
}
