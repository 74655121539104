import { environment } from "src/environments/environment";

export const cookieTokenIdName = "CognitoIdentityServiceProvider.18s0tft5v5nepjuq5ucqdlba7i.f93cc4ba-1fed-46da-b9de-1d636e618e90.idToken"
export const UrlLambdaApi = environment.urlAPI;
export const appsDomain = ["https://app.edutin.com", "https://apptemp.edutin.com", "http://127.0.0.1:4200"];
export const allowedOrigins = (origin: string) => appsDomain.includes(origin);
// export const allowedOrigins = (origin: string) => origin.includes('.edutin.com') || origin == 'http://127.0.0.1:4200'


export interface DataInput {
    User: User
    location: string
    classroomView: ClassroomModel
    ProfileView: Profile
    Ad: AdModel,
    scholarship: any,
    showing: boolean,
    pause: boolean | undefined
    certificate_status: number,
    courseProgress: number,
    certificate_id: string,
    mobile: boolean,
    is_video: boolean,
}

export interface ClassroomModel {
    course_id: string | number;
    module_id: string | number | undefined;
    class_id: string | number | undefined;
    source_id: string | number | undefined;

    [key: string]: string | number | undefined; // Index signature
}

export interface AdModel {
    id: string
    principal_text: string
    category_id?: string
    title: string
    cta: string
    style?: StyleModel,
    media?: any,
    name: string,
    result: string,
    scope: string,
    prints: string,
    status: string,
    type: string,
    description: string,
    target_website: any,
    target_form: Boolean,
    public_id: string,
    group_id: string,
    campaign_id: string,
    creator_id: string,
    event_id: string,
    Event: any
    shown: boolean,
    closed: number,
    content_id: string,
    poll?: any
    vacancy?: any
    ad_course?: CourseAdModel
    config?: string
    parent: string
    inaugural: boolean
    tracking: any
    owner_id: string
    interactions?: any
}

export interface dataEmitterEvent {
    hasAd?: boolean;
    show?: boolean;
    adInteraction?: string | null | undefined; // CLOSED - TO_LINK - CTA_CLICK
    adData?: AdDataModel;
    type?: string;
    url?: string;
    classroomView?: ClassroomModel
}

interface AdDataModel {
    actions?: Array<string>,
    type?: string
    url?: string,
}

interface StyleModel {
    text_align_description: string
    text_align_title: string
    color: string
    background: string
    btn_background: string
    btn_color: string
}

export interface CourseAdModel {
    id: string
    course_id: number | string
    category_id: number | string
    ad_id: string
    module_id: Array<any>
    class_id: Array<any>
    source_id: Array<any>
    created: string
    creator_id: number | string
    viewed: boolean

    [key: string]: string | number | boolean | Array<any> | undefined; // Index signature


}

export interface Poll {
    owner_id: string;
    id: string;
    created_at: string;
    title: string;
    count_questions: number;
    questions: Array<Question>;
}

export interface vacancy {
    business_id: string,
    ubication: string,
    post: string,
    modality: string,
    name: string,
    state: string,
    aptitudes: Array<string>,
    responsibility: string,
    project_id: string,
    description: string,
    id: string,
    job_type: string,
    Project: any,
    business: any,
}

export interface Question {
    poll_id: string;
    id: string;
    order: number;
    options: Array<Option>;
    media: any
    title: string;
    required: boolean;
    type: string;
    conditional: boolean;
    answered: any;
    parent_id: string;
}

export interface Option {
    id: string;
    value: string;
    label: string;
    redirect?: string;
    selected?: boolean;
}

export interface Profile {
    user_id: number | string
}


export interface User {
    id: number | string;
    token: string;
}
