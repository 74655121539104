<div class="first-template">
  <p class="card-title mobile" [ngStyle]="{'text-align' : notice?.style?.text_align_title}">
    {{notice?.title ? notice.title : 'Titulo del anuncio aquí'}}</p>

  <div class="body">
    <div class="left">
      <img [src]="notice?.media?.url || '../../../..//assets/img/img-default.png'" alt="">
    </div>

    <div class="right">

      <p class="card-title d-top" [ngStyle]="{'text-align' : notice?.style?.text_align_title}">
        {{notice?.title ? notice.title : 'Titulo del anuncio aquí'}}</p>
  
      <div class="principal-text" [ngStyle]="{'text-align' : notice?.style?.text_align_description}">
        <p>{{notice?.principal_text ? notice.principal_text : 'Texto principal aquí'}}</p>
  
        <div class="body-buttons">
          <button class="cta secondary" (click)="clickCertification.emit()">Mas información</button>
          <button class="cta" (click)="clickUrl.emit()">{{notice && notice?.cta
            ? (notice.cta | ctaType) : 'Ver mas'}}</button>
        </div>
      </div>

    </div>
    
  </div>
  
  <tool-bar [timer]="counter" [timeToSkip]="waitTime" [typeNotice]="notice.type" (playPause)="playPause.emit()" (skip)="closed.emit(true)"></tool-bar>
</div>