import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { interval } from 'rxjs';

@Component({
  selector: 'tool-bar',
  templateUrl: './tool-bar.component.html',
  styleUrls: ['./tool-bar.component.scss']
})
export class ToolBarComponent {


  @Input() timer: number;
  @Input() videoLength: number;
  @Input() timeToSkip: number;
  @Input() typeNotice: string;
  @Input() loadingVideo: Boolean;
  @Output() skip = new EventEmitter<any>();
  @Output() volumeChange = new EventEmitter<number>();
  @Output() playPause = new EventEmitter<any>();
  skip_timer = 0;
  volume = 1;
  adPaused = false;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['timer'] && this.typeNotice == 'VIDEO') {
      if (parseInt(this.timer.toString()) <= this.timeToSkip) {
        this.skip_timer = this.timeToSkip - parseInt(this.timer.toString());
      }
    }
  }

  ngOnInit(): void {
    this.skip_timer = this.timeToSkip;

    if(this.typeNotice != 'VIDEO') {
      this.counterAd();
    }
  }
  ngOnDestroy(): void {
    if(this.interval_subscription) {
      this.interval_subscription.unsubscribe();
    }
  }

  interval_subscription: any;
  counterAd() {
    if (this.interval_subscription) {
      this.interval_subscription.unsubscribe(); // Cancela el intervalo existente si hay uno en progreso
    }

    this.interval_subscription = interval(1000).subscribe(() => {
      if (this.skip_timer > 0) {
        this.skip_timer--;
      } else {
        this.interval_subscription.unsubscribe();
      }
    });
  }

  display_event_efect = false;
  onclickPlay(event?:string) {
    
    this.playPause.emit();
    this.adPaused = !this.adPaused;

    if (event == 'displayed') {
      this.display_event_efect = true
      setTimeout(() =>  this.display_event_efect = false, 800);
    }
  }

  onVolumeChange() {
    this.volumeChange.emit(this.volume);

    const range = document.getElementById('range') as HTMLInputElement | null;
    if (!range) return; // add null check
    const min = 0;
    const max = 1;
    const val = this.volume;
    let percentage = (val - min) * 100 / (max - min);

    range.style.backgroundSize = percentage + '% 100%';
  }

  lastVolume = 1;
  onClickVolume() {
    if (this.volume == 0) {
      this.volume = this.lastVolume;
      this.onVolumeChange();
    } else {
      this.lastVolume = this.volume;
      this.volume = 0;
      this.onVolumeChange();
    }
  }
}

