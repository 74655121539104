<div class="vacancy">
  <div class="header">
    <div class="cross" (click)="closed.emit()">
      <span class="icon-cross"></span>
    </div>
    <img class="logo-company" [src]="vacancy?.business?.logo ? vacancy.business.logo : 'https://d3puay5pkxu9s4.cloudfront.net/Users/default/medium_imagen.jpg'" alt="">
    <div class="title">
      <span class="name-vacancy">{{vacancy.name}}</span>
      <div class="subtitle">
        <span class="name-company">{{vacancy?.business?.name}} - </span>
        <span class="location">{{vacancy.ubication}} - </span>
        <span class="modality">{{vacancy.modality}}</span>
      </div>
    </div>
  </div>
  <div class="body">
    <div class="left">
      <span class="title">Descripción</span>
      <p class="description">
        {{vacancy.description}}
      </p>
    </div>
    <div class="right">
      <div class="group">
        <span class="title">Nivel de responsabilidad</span>
        <span>
          {{vacancy.responsibility}}
        </span>
      </div>
      <div class="group">
        <span class="title">Tipo de empleo</span>
        <span>
          {{vacancy.job_type}}
        </span>
      </div>
      <div class="group">
        <span class="title">Cargo</span>
        <span>
          {{vacancy.post}}
        </span>
      </div>

    </div>

  </div>
  <button class="cta">Postularse</button>
</div>