import { Component, EventEmitter, Input, Output } from '@angular/core';
import { interval } from 'rxjs';
import { AdModel } from 'src/app/models/app.models';

@Component({
  selector: 'informative-notice',
  templateUrl: './informative-notice.component.html',
  styleUrls: ['./informative-notice.component.scss']
})
export class InformativeNoticeComponent {

  @Input() notice: AdModel;
  @Input() counter: number;
  @Output() closed = new EventEmitter<boolean>();
  @Output() clickCertification = new EventEmitter<boolean>();
  @Output() clickUrl = new EventEmitter<boolean>();
  @Output() playPause = new EventEmitter<any>();

  waitTime = 0;

  constructor() { }

  ngOnInit(): void {
    this.waitTime = this.notice.Event.skip_options.wait_time
  }

  interval_subscription : any;
  counterAd() {
    if (this.interval_subscription) {
      this.interval_subscription.unsubscribe(); // Cancela el intervalo existente si hay uno en progreso
    }

    this.counter = 15;
    if (this.counter > 0) {
      this.interval_subscription = interval(1000).subscribe(() => {
        if (this.counter > 0) {
          this.counter--;
        } else {
          this.interval_subscription.unsubscribe();
        }
      });
    }
  }

}
