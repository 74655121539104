import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AdModel } from 'src/app/models/app.models';

@Component({
  selector: 'membership',
  templateUrl: './membership.component.html',
  styleUrls: ['./membership.component.scss']
})


export class MembershipComponent {
  @Input() notice: AdModel;
  @Input() counter: number;
  @Output() closed = new EventEmitter<boolean>();
  @Output() playPause = new EventEmitter<any>();
  @Output() clickCertification = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
    
  }

}
