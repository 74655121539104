<div #card class="card" *ngIf="notice && showAd">
    <div class="notice-classroom" *ngIf="location == 'classroom'">
        <informative-notice *ngIf="notice?.type == 'INFORMATIVE'" [notice]="notice" [counter]="counter" (clickCertification)="goToUrl('more_info')" (clickUrl)="goToUrl()" (closed)="closeNotice($event)" (playPause)="playPause()"></informative-notice>

        <discount *ngIf="notice?.type == 'DISCOUNT'" [notice]="notice" [counter]="counter" [scholarship]="data?.scholarship" (playPause)="playPause()" (clickCertification)="goToUrl()" (closed)="closeNotice($event)"></discount>

        <img-notice *ngIf="notice?.type == 'IMAGE'" [notice]="notice" [counter]="counter" (closed)="closeNotice()" (clickUrl)="goToUrl()"></img-notice>

        <video-notice *ngIf="notice?.type == 'VIDEO'" [notice]="notice" [mobile]="data.mobile" [course]="data?.classroomView" [data]="data" (finished)="closeNotice($event)" (playPause)="playPause()"></video-notice>

        <poll *ngIf="notice?.type == 'POLL' && notice.poll" [notice]="notice" [counter]="counter" [poll]="notice.poll" (finish)="pollFinished()"></poll>

        <membership *ngIf="notice.type == 'MEMBERSHIP'" [notice]="notice"  [counter]="counter" (playPause)="playPause()" (closed)="closeNotice($event)"></membership>

        <vacancy *ngIf="notice?.type == 'VACANCY' && notice.vacancy" [vacancy]="notice.vacancy" (closed)="closeNotice()"></vacancy>
    </div>

    <div class="notice-profile" *ngIf="location == 'profile'"
        [ngStyle]="{'background-color' : notice.style?.background, 'color' : notice.style?.color}">
        <div *ngIf="notice?.type == 'default'">
            <p id="myP" class="card-title" [ngStyle]="{'text-align' : notice.style?.text_align_title}">
                {{notice.title ? notice.title : ''}}
            </p>
            <div class="img-container" [ngClass]="{'largeTitle' : p?.clientHeight > 24 }"
                *ngIf="notice.media?.url && notice.type == 'IMAGE' || notice.type == 'default'">
                <img *ngIf="notice.media?.url" [src]="notice.media?.url" alt="">

            </div>
            <p class="principal-text" [ngStyle]="{'text-align' : notice.style?.text_align_description}">
                {{notice.principal_text ? notice.principal_text : ''}}
            </p>

            <div class="cta profile" *ngIf="notice?.cta != 'Sin botón'"
                [ngStyle]="{'background-color' : notice.style?.btn_background, 'color': notice.style?.btn_color}">
                {{notice && notice.cta ? notice.cta : 'Mas información'}}
            </div>
        </div>

        <div class="container-default" *ngIf="notice?.type != 'default'">
            <img [src]="notice.media?.url || '../../../..//assets/img/img-default.png'" alt="">

            <div class="cta" *ngIf="notice?.cta != 'Sin botón'"
                [ngStyle]="{'background-color' : notice.style?.btn_background, 'color': notice.style?.btn_color}">
                {{notice && notice.cta ? notice.cta : 'Mas información'}}
            </div>
        </div>
    </div>
</div>
