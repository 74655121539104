<div class="tool-bar">
    
    <!-- Skip Buttom -->
    <div class="skip">
        <span *ngIf="skip_timer > 0">{{skip_timer}}</span>
        <span *ngIf="skip_timer <= 0" (click)="skip.emit()">Omitir  <span class="icon-next"></span></span>
    </div>

    <div class="progress-bar-container">
        <div class="progress-bar" [style.width.%]="(timer / (videoLength ? videoLength : 15)) * 100"></div>
    </div>
    <div class="controls">
        <div class="left">
            <div class="icon-container play" (click)="onclickPlay()">
                <span *ngIf="adPaused" class="icon-play-arrow"></span> 
                <span *ngIf="!adPaused" class="icon-pause"></span>
            </div>  
            <div class="icon-container volume-container">
                <span *ngIf="typeNotice == 'VIDEO' && volume >= 0.7" class="icon-volume-high icon-v" (click)="onClickVolume()"></span>
                <span *ngIf="typeNotice == 'VIDEO' && volume >= 0.2 && volume < 0.7" class="icon-volume-medium icon-v" (click)="onClickVolume()"></span>
                <span *ngIf="typeNotice == 'VIDEO' && volume > 0 && volume < 0.2" class="icon-volume-low icon-v" (click)="onClickVolume()"></span>
                <span *ngIf="typeNotice != 'VIDEO' || (typeNotice == 'VIDEO' && volume == 0)" class="icon-mute icon-v" (click)="typeNotice == 'VIDEO' ?  onClickVolume() : null"></span>

                <input class="range-volume" id="range" *ngIf="typeNotice == 'VIDEO'" type="range" min="0" max="1" step="0.1" [(ngModel)]="volume" (input)="onVolumeChange()">
            </div>  

        </div>
        <div class="right">
            <!-- <div class="icon-container">
                <span class="icon-subtitles"></span>
            </div>  
            <div class="icon-container">
                <span class="icon-settings"></span>
            </div>  
            <div class="icon-container">
                <span class="icon-fullscreen"></span>
            </div>   -->
        </div>
    </div>

    <div class="display-event-listener" (click)="onclickPlay('displayed')">

        <div class="control-play" [class.show]="display_event_efect">
            <span *ngIf="adPaused" class="icon-play-arrow"></span> 
            <span *ngIf="!adPaused" class="icon-pause"></span>
        </div>

    </div>
</div>
