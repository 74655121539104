<div class="container">
  <div class="poll">
    <div class="body">
      <div class="progress-bar-cross" *ngIf="notice.Event.skip_options.skip_timer"
          [ngStyle]="{'background': counter == 0 ? '#F2F6FF': 'radial-gradient(closest-side, white 79%, transparent 80% 100%), conic-gradient(#2A79FF calc('+counter+'*'+ (100/waitTime)+'%), #F2F6FF 0)', 'cursor': counter == 0 ? 'pointer': null}"
              (click)="counter == 0 ? finish.emit() : null ">
              <span *ngIf="counter > 0">{{counter}}</span>
              <span *ngIf="counter == 0" class="icon-cross progress"></span>
      </div>
  
      <div class="title-poll">
        <p>{{poll?.title}}</p>
      </div>
  
      <div class="progress-container">
        <div class="counter">
          {{counterQuestions <= totalQuestions ? counterQuestions : totalQuestions}} / {{totalQuestions}}
        </div>
  
        <div class="progress-bar">
          <div class="progress" [ngStyle]="{'width.%': ((counterQuestions-1)/totalQuestions) * 100}"></div>
        </div>
      </div>
      
      <div class="answers">
        <p class="title">{{poll?.questions[currentQuestion].title}}</p>
        
        <ng-container *ngIf="poll?.questions[currentQuestion].type == 'UNIQUE_CHOICE' || 'MULTIPLE_CHOICE'">
          <div class="option" *ngFor="let option of poll?.questions[currentQuestion].options"
            (click)="selectOption(option)" [ngClass]="{'active': option.selected}">
            <span>{{option.label}}</span>
          </div>
        </ng-container>
  
        <ng-container *ngIf="poll?.questions[currentQuestion].type == 'TEXT_SHORT'">
          <textarea class="input-text" rows="4" [(ngModel)]="shorText"></textarea>
        </ng-container>
        
        
      </div>
    </div>
    <div class="footer">
      <button (click)="nextQuestion()" [disabled]="(poll?.questions[currentQuestion].type == 'TEXT_SHORT' && shorText.length == 0) || (poll?.questions[currentQuestion].type != 'TEXT_SHORT' && !isAnswered())">{{counterQuestions < totalQuestions ? 'Siguiente' : 'Terminar'}}</button>
  
    </div>
  </div>
</div>