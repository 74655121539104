<div class="second-template" [ngClass]="{'scholarship' : scholarship}">

  <div class="body">
    <div class="colums">
      <img class="avatar" src="https://d3puay5pkxu9s4.cloudfront.net/academy/imagenes/others/certificates/certificate-img.png"
        alt="">

      <div class="right">
        <p class="principal-text" *ngIf="scholarship">Pásate a la opción <span class="blue">Estudiar con
            Certificado</span> y obtén:
        </p>
        <p class="principal-text" *ngIf="!scholarship">Pásate a la opción <span class="blue">Estudiar con
            Certificado</span> y disfruta de:
        </p>

        <div class="discount-container" *ngIf="scholarship">
          <span class="large">{{scholarship?.discount}}% off</span>
          <s>{{scholarship?.value_local}}</s> /
          {{scholarship?.value_local_discount}}
          {{scholarship?.currency}}
        </div>

        <ul class="item-list">
          <li>
            <span class="icon-tick"></span>
            <span *ngIf="scholarship">{{notice.description.split(',')[0]}}</span>
            <span *ngIf="!scholarship">Revisión de actividades</span>
          </li>
          <li>
            <span class="icon-tick"></span>
            <span *ngIf="scholarship">{{notice.description.split(',')[1]}}</span>
            <span *ngIf="!scholarship">Certificación internacional</span>
          </li>
          <li>
            <span class="icon-tick"></span>
            <span *ngIf="scholarship">{{notice.description.split(',')[2]}}</span>
            <span *ngIf="!scholarship">Asistencia personalizada</span>
          </li>
        </ul>

        <button class="cta" (click)="clickCertification.emit()" *ngIf="scholarship">{{notice && notice?.cta
          ? (notice.cta | ctaType) : 'Mas información'}}
        </button>
        <button class="cta" (click)="clickCertification.emit()" *ngIf="!scholarship">
          Estudiar con certificado
        </button>

        <div class="valid" *ngIf="scholarship">
          <span>
            <img class="flag" width="20" height="20" [src]="scholarship.url">
            Válido para {{scholarship.name}}, hasta el
            {{scholarship.vencimiento}}
          </span>
        </div>
      </div>

    </div>

  </div>
  <tool-bar [timer]="counter" [timeToSkip]="waitTime" [typeNotice]="notice.type" (playPause)="playPause.emit()" (skip)="closed.emit(true)"></tool-bar>
</div>