import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { SpeedTestModule } from 'ng-speed-test';
import { PollComponent } from './components/poll/poll.component';
import { FormsModule } from '@angular/forms';
import { InformativeNoticeComponent } from './components/informative-notice/informative-notice.component';
import { DiscountComponent } from './components/discount/discount.component';
import { VideoNoticeComponent } from './components/video-notice/video-notice.component';
import { ImgNoticeComponent } from './components/img-notice/img-notice.component';
import { VacancyComponent } from './components/vacancy/vacancy.component';
import { ToolBarComponent } from './components/tool-bar/tool-bar.component';
import { CtaTypePipe } from './pipes/cta-type.pipe';
import { MembershipComponent } from './components/membership/membership.component';
@NgModule({
  declarations: [
    AppComponent,
    PollComponent,
    InformativeNoticeComponent,
    DiscountComponent,
    VideoNoticeComponent,
    ImgNoticeComponent,
    VacancyComponent,
    ToolBarComponent,
    CtaTypePipe,
    MembershipComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SpeedTestModule,
    FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
