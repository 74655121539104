import { Injectable } from '@angular/core';
import { Observable, Subject, catchError, map, of, retry } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AdModel, CourseAdModel, DataInput, UrlLambdaApi, User, dataEmitterEvent } from './models/app.models';
import jwtDecode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  public token: string
  public user_data: User

  /**Variable que contiene todos los anuncios que tiene un curso */
  public coursesAds: Array<AdModel> = []

  public iframeEmitter = new Subject<dataEmitterEvent>();

  constructor(
    private http: HttpClient,
  ) { }

  getHeadersLambda(): any {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token
    })

    return ({ headers: headers });
  }

  parseUserToken(data: DataInput): void {
    const { token } = data.User
    if (token) {
      this.token = token
      this.user_data = jwtDecode(token);
    }
    
  }


  getAds(params: Object): Observable<any> {
    
    const qparams = this.buildQueryParams(params);

    let url = `${UrlLambdaApi}/notices?${qparams}`;
    return this.http.get(url, this.getHeadersLambda())
      .pipe(map((response: any) => {

        const { data, status, LastEvaluatedKey } = response;

        let data_to_return = { valid: status, data: []}

        if (status) {
          data_to_return.data = JSON.parse(JSON.stringify(data));
        }
        return data_to_return
      }),

        retry(3),
        catchError(this.handleError<any>('getCampaigns', []))
      );
  }


  getAdsCourses(course_id?: string | number | undefined,):Observable<any> {

    return this.http.get(`${UrlLambdaApi}/courses/notices?course_id=${course_id}&details=true`, this.getHeadersLambda()).pipe(
      map((response: any) => {
        const { status, data, LastEvaluatedKey } = response;

        let data_to_return = { valid: status, data: [] }

        if (status) {
          data_to_return.data = data
        }

        return data_to_return

      })
    );
  }

  getUserTracking(ad_id:string, user_id: string | number, course_id?: string | number): Observable<any> {
    return this.http.get(`${UrlLambdaApi}/notices/tracking?notice_id="${ad_id}"&user_id="${user_id}"` + (course_id ? `&course_id="${course_id}"` : ''), this.getHeadersLambda()).pipe(
      map((response: any) => {
        const { status, data, LastEvaluatedKey } = response;

        let data_to_return = { valid: status, data: [] }

        if (status) {
          data_to_return.data = data[0]
        }

        return data_to_return
      })
    );
  }

  postUserAdTracking(ad_id: string, user_id: string | number, data: any):Observable<any> {
    return this.http.post(`${UrlLambdaApi}/notices/"${ad_id}"/users/"${user_id}"/tracking`, { data }, this.getHeadersLambda()).pipe(
      map((response: any) => {
        const { status, data } = response;

        let data_to_return = { valid: status, data: [] }

        if (status) {
          data_to_return.data = data
        }

        return data_to_return

      })
    );
  }

  putUserAdTracking(tracking_id: string, data: any):Observable<any> {
    return this.http.put(`${UrlLambdaApi}/tracking/"${tracking_id}"`, { data }, this.getHeadersLambda()).pipe(
      map((response: any) => {
        const { status, row_count } = response;

        let data_to_return = { valid: status, row_count }

        return data_to_return

      })
    );
  }

  getPollById(id: string): Observable<any> {
    return this.http.get(`${UrlLambdaApi}/polls?poll_id="${id}"`, this.getHeadersLambda())
      .pipe(map((form: any) => {
        const { data, status } = form;
        let data_return = { valid: status, data: [] }

        if (status) {
          data_return.data = JSON.parse(JSON.stringify(data));
        }
        return data_return
      }),
        retry(3),
        catchError(this.handleError<any>('getFormByID', []))
      );
  }

  getQuestions(poll_id: string): Observable<any> {
    return this.http.get(`${UrlLambdaApi}/polls/"${poll_id}"/questions`, this.getHeadersLambda())
      .pipe(map((form: any) => {
        const { data, status } = form;
        let data_return = { valid: status, data: [] }

        if (status) {
          data_return.data = JSON.parse(JSON.stringify(data));
        }
        return data_return
      }),
        retry(3),
        catchError(this.handleError<any>('getForms', []))
      );
  }

  postAnswer(question_id: string ,data: any): Observable<any> {
    return this.http.post(`${UrlLambdaApi}/polls/questions/"${question_id}"/answers`, { data }, this.getHeadersLambda())
      .pipe(map((form: any) => {
        const { data, status } = form;
        let data_return = { valid: status, data: [] }

        if (status) {
          data_return.data = JSON.parse(JSON.stringify(data));
        }
        return data_return
      }),
        retry(3),
        catchError(this.handleError<any>('addUserForm', null))
      );
  }

  putAnswer(question_id: string, answer_id: string, data: object): Observable<any> {
    return this.http.put(`${UrlLambdaApi}/polls/questions/"${question_id}"/answers/"${answer_id}"/`, { data }, this.getHeadersLambda())
      .pipe(map((question: any) => {
        const { row_count, status } = question;
        let data_return: boolean = false;
        if (status && row_count >= 1) {
          data_return = true;
        }
        return data_return;
      }),
        retry(5),
        catchError(this.handleError<any>('updateQuestion', []))
      );
  }

  getVacancy(vacancy_id : string): Observable<any> {

    return this.http.get(`https://api.edutin.com/b/d/vacancies?vacancy_id="${vacancy_id}"`, this.getHeadersLambda())
      .pipe(map((campaigns: any) => {

        const { data, status, LastEvaluatedKey } = campaigns;
        let data_return = { valid: status, data: [], last: LastEvaluatedKey };

        if (status) {
          data_return.data = JSON.parse(JSON.stringify(data));
        }
        return data_return
      }),
        retry(3),
        catchError(this.handleError<any>('getVacancy', []))
      );
  }

  getBusiness(business_id : string): Observable<any> {

    return this.http.get(`https://api.edutin.com/b/d/business/"${business_id}"/profile`, this.getHeadersLambda())
      .pipe(map((campaigns: any) => {

        const { data, status, LastEvaluatedKey } = campaigns;
        let data_return = { valid: status, data: [], last: LastEvaluatedKey };

        if (status) {
          data_return.data = JSON.parse(JSON.stringify(data));
        }
        return data_return
      }),
        retry(3),
        catchError(this.handleError<any>('getBusiness', []))
      );
  }

  getCountPayments(user_id : string | number): Observable<any> {
    return this.http.get(`${UrlLambdaApi}/users/${user_id}/payments?pages=true&estado=1`, this.getHeadersLambda())
      .pipe(map((payments: any) => {
        const { count, pages } = payments;
        let data_return = { count, pages };
        return data_return
        
      }),
        retry(3),
        catchError(this.handleError<any>('getBusiness', []))
      );
  }

  /**
   * Construye una cadena de parámetros de consulta codificada para URL a partir de un objeto de parámetros dado.
   * 
   * Esta función itera sobre cada par clave-valor en el objeto `params`, realiza las siguientes operaciones:
   * - Filtra los pares donde el valor es `null`, ya que no se deben incluir en la cadena de consulta.
   * - Convierte los valores de tipo `string` en valores encerrados entre comillas.
   * - Convierte los valores de tipo `object` (incluidos los arrays) en cadenas JSON.
   * - Mantiene los demás tipos de valores tal cual (numéricos, booleanos, etc.).
   * - Codifica tanto las claves como los valores para asegurar la compatibilidad con URL.
   * 
   * Los pares clave-valor codificados se unen con `&`, formando la cadena de consulta final que se puede añadir a una URL.
   * 
   * @param params Objeto que contiene los parámetros que se quieren convertir en una cadena de consulta. Cada clave del objeto representa un parámetro de consulta, y el valor asociado a esa clave representa el valor del parámetro.
   * @returns Una cadena de caracteres que representa los parámetros de consulta codificados para URL, lista para ser añadida a una URL de petición HTTP.
 */
  private buildQueryParams(params: Object): string {
    const encodedParams = Object.entries(params)
      .filter(([key, value]) => value !== null && value !== undefined) // Filtrar entradas con valores null
      .map(([key, value]) => {
        // Determinar el formato del valor
        let formattedValue;
        if (typeof value === 'string') {
          // Encerrar entre comillas si el valor es un string
          formattedValue = `"${value}"`;
        } else if (typeof value === 'object') {
          // Convertir en cadena JSON si el valor es un objeto
          formattedValue = JSON.stringify(value);
        } else {
          formattedValue = value;
        }
        return `${encodeURIComponent(key)}=${encodeURIComponent(formattedValue)}`;
      })
      .join('&');

    return encodedParams
  }


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.log(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

}
