import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AdModel } from 'src/app/models/app.models';

@Component({
  selector: 'img-notice',
  templateUrl: './img-notice.component.html',
  styleUrls: ['./img-notice.component.scss']
})
export class ImgNoticeComponent {
  
  @Input() notice: AdModel;
  @Input() counter: number;
  @Output() clickUrl = new EventEmitter<any>();
  @Output() closed = new EventEmitter<any>();

  waitTime = 0;
  constructor() { }

  ngOnInit(): void {
    this.waitTime = this.notice.Event.skip_options.wait_time
    
  }

}
