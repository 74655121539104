import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'ctaType' })
export class CtaTypePipe implements PipeTransform {
  transform(value: string): any {
    if (value == undefined) return '--'

    const cta: { [key: string]: string } = {
      'get_certificate': 'Estudiar con certificado',
      'buy': 'Comprar',
      'enroll': 'Registrarse',
      'more_info': 'Más información',
      'download': 'Descargar',
      'contact': 'Contactarnos',
      'get_discount': 'Obtener descuento',
      'get_membership': 'Comprar membresía',
    };
    return cta[value] || 'Estudiar con certificado';
  }
}
