import { Component, EventEmitter, Input, Output } from '@angular/core';
import { vacancy } from 'src/app/models/app.models';

@Component({
  selector: 'vacancy',
  templateUrl: './vacancy.component.html',
  styleUrls: ['./vacancy.component.scss']
})
export class VacancyComponent {

  @Input() vacancy: vacancy;
  @Output() closed = new EventEmitter<any>();
  
  constructor() { }

  ngOnInit(): void {
    
  }
}
